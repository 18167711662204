import React from 'react'
import { Card, Text } from 'theme-ui'
import Section from '@components/Section'

const Commitment = props => (
  <Section aside title='We Want You !' {...props}>
    <Card variant='paper'>
      <Text variant='p'>
        We are looking forward to hearing your voice, if you have something to say join our community !
        <p>Please, have a look to our Faqs and copyright policy or if you wish to be featured here.</p>
      </Text>
    </Card>
  </Section>
)

export default Commitment

import React from 'react'
import { Stack, Main, Sidebar } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import ContactForm from '@widgets/ContactForm'
import ContactInfo from '@widgets/ContactInfo'
import Commitment from '@widgets/Commitment'

export default () => (
  <>
    <Seo title='Contact' />
    <Divider />
    <Stack>
      <Main>
        <PageTitle
          header="Let's Connect"
          subheader='Here is the place! Are you a fashion - designer - developer - maker ? Have you something interesting to share or you like to be part of FTTalks.com ? If you need more informations on our blog, if you want to mail us or if you think some content looks inappropriate, please feel free to write it down. Even if you want to say "Hi", you are welcome to let us know!'
        />
        <Divider />
        <ContactForm />
      </Main>
      <Sidebar>
        <Commitment />
        <Divider />
        <ContactInfo />
      </Sidebar>
    </Stack>
  </>
)
